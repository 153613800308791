<template>
  <div class="relative overflow-x-auto bg-white">
    <div v-if="props.loader">
      <TableSkeletonLoader :loader="props.loader" :cols="4" :rows="8" />
    </div>
    <div v-else>
      <table
        v-if="tableData?.data?.length > 0"
        class="w-full text-sm text-left"
      >
        <thead class="text-sm text-white bg-teal border-b">
          <tr>
            <th
              scope="col"
              class="px-6 py-4 tracking-wider"
              v-for="header in props.tableHeadings"
              :key="header"
            >
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="border-b" v-for="data in tableData.data" :key="data">
            <td
              class="px-6 py-4 w-2/5 font-medium text-gray-900 overflow-hidden"
            >
              <span class="py-2">{{ data?.feedback }}</span>
            </td>
            <td class="px-6 py-4">
              {{ moment(data.created_at).format("DD/MM/YYYY") }}
            </td>
            <td class="px-6 py-4">
              {{ data?.practice.practice_name }}
            </td>
            <td class="px-6 py-4">
              <div
                class="status align-middle flex justify-left items-center w-max rounded-lg h-6 p-2"
                :class="{
                  'bg-red-50 text-red': data?.feedback_type === 'negative',
                  'bg-green-50 text-green': data?.feedback_type === 'positive',
                }"
              >
                <span
                  class="text-md capitalize"
                  :class="{
                    'text-red': data?.feedback_type === 'negative',
                    'text-green': data?.feedback_type === 'positive',
                  }"
                  >{{ data?.feedback_type }}</span
                >
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else>
        <div
          class="flex flex-col items-center justify-center text-center w-full py-10"
        >
          <div class="rounded-full p-12 icon-bg">
            <img src="../../../assets/icons/noData.svg" class="w-32" />
          </div>
          <div class="mt-4 text-teal font-medium flex align-middle">
            No Data Found.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRefs } from "vue";
import moment from "moment";

import TableSkeletonLoader from "@/components/newUi/atoms/TableSkeletonLoader.vue";

const props = defineProps({
  tableData: {
    type: Array,
    required: true,
  },
  tableHeadings: {
    type: Array,
    required: true,
  },
  loader: {
    type: Boolean,
    required: true,
  },
});

const { tableData } = toRefs(props);
</script>

<style scoped>
.icon-bg {
  background: rgba(235, 248, 247, 1);
}
</style>
