<template>
  <div class="flex flex-col gap-8">
    <h3 class="font-medium text-xl">Positive and Negative Feedback</h3>
    <div class="border-2 border-grey-700 rounded-md">
      <div class="flex space-x-4 justify-end p-6 z-10">
        <div class="w-1/3 bg-white border border-gray-300 rounded-md">
          <DatePicker
            :selectedDate="selectedDate"
            @selected-date="filterFeedbacksByDate"
            @cleared-date="filterFeedbacksByDate"
            placeholder="Date"
          />
        </div>
        <v-select
          class="w-1/3"
          label="practice_name"
          placeholder="Practice Name"
          :options="practices"
          @update:modelValue="filterFeedbacksByPractice"
        >
        </v-select>
        <v-select
          class="w-1/3"
          label="label"
          placeholder="Type of Feedback"
          :searchable="false"
          :options="feedbackTypeList"
          @update:modelValue="filterFeedbackByType"
        >
        </v-select>
      </div>
      <FeedbacksTable
        :tableData="localFeedbacks"
        :tableHeadings="feedbacksTableHeadings"
      />
      <Pagination
        @page-changed="fetchPage"
        :currentPage="localFeedbacks?.current_page"
        :totalPages="localFeedbacks?.last_page"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import FeedbacksTable from "@/components/newUi/tables/FeedbacksTable.vue";
import Pagination from "@/components/newUi/atoms/Pagination.vue";
import DatePicker from "@/components/newUi/atoms/DatePicker.vue";

const store = useStore();
const feedbacksTableHeadings = ref([
  "Feedbacks",
  "Date",
  "Practice",
  "Type of Feedback",
]);
const feedbackType = ref(null);
const practiceId = ref(null);
const selectedDate = ref(null);
const localFeedbacks = ref([]);
const practices = ref([]);

onMounted(async () => {
  await store
    .dispatch("patientSurveys/fetchSurveyFeedbacks")
    .then((response) => {
      localFeedbacks.value = response;
    });
  await store.dispatch("patientSurveys/loadPractices").then((response) => {
    practices.value = response;
  });
  applyFiltersAndFetch();
});

const applyFiltersAndFetch = async (
  page = localFeedbacks.value.current_page
) => {
  try {
    const filters = {
      date: selectedDate.value,
      feedbackType: feedbackType?.value?.toLowerCase(),
      practice: practiceId.value,
      page: page,
    };
    const response = await store.dispatch(
      "patientSurveys/fetchSurveyFeedbacks",
      filters
    );
    localFeedbacks.value = response;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const filterFeedbackByType = (value) => {
  feedbackType.value = value;
  applyFiltersAndFetch();
};

const filterFeedbacksByPractice = (value) => {
  if (value) {
    practiceId.value = value.id;
  } else {
    practiceId.value = null;
  }
  applyFiltersAndFetch();
};

const filterFeedbacksByDate = (dateSelected) => {
  if (dateSelected) {
    selectedDate.value = dateSelected;
  } else {
    selectedDate.value = null;
  }
  applyFiltersAndFetch();
};

const feedbackTypeList = ref(["Positive", "Negative"]);

const fetchPage = (page) => {
  if (page > localFeedbacks.value.last_page) {
    return;
  } else {
    applyFiltersAndFetch(page);
  }
};
</script>

<style scoped>
::v-deep .vs__dropdown-toggle {
  padding: 8px;
  background-color: white;
  cursor: pointer !important;
}
::v-deep ul li:not(:hover) {
  background-color: white !important;
  color: black;
}
::v-deep ul li:hover {
  background-color: teal !important;
}
</style>
